<!-- eslint-disable import/no-extraneous-dependencies -->
<template>
  <b-container fluid>
    <b-row class="justify-content-md-center">
      <b-col md="12">
        <b-overlay
          :show="offreProcess"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-lg
          rounded="sm"
        >
          <b-card class="mb-0 my-5">
            <form-wizard
              color="#7367F0"
              :title="'Modifier un fret'"
              subtitle=""
              finish-button-text="Soumettre"
              back-button-text="Précédent"
              back-button-size="sm"
              next-button-text="Suivant"
              class="steps-transparent mb-3 font-size"
              justified
              @on-complete="storeOffre()"
            >
              <!-- account detail tab -->
              <tab-content :before-change="ValidationTrajetForm">
                <validation-observer
                  ref="trajetRules"
                  tag="form"
                >
                  <b-row>
                    <b-col
                      md="12"
                      class="mb-2"
                    >
                      <h5 class="mb-0">
                        Informations sur le trajet
                      </h5>
                      <small class="text-muted" />
                    </b-col>

                    <b-col md="6">
                      <b-form-group
                        label="Information sur départ de la marchandise"
                        class="text-primary"
                      />

                      <b-form-group
                        label="Pays"
                        label-for="country_depart"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="pays"
                          rules="required"
                        >
                          <v-select
                            id="country_depart"
                            v-model="form.country_depart"
                            label="country_name"
                            :state="errors.length > 0 ? false : null"
                            :options="countries"
                            placeholder="Choisir un pays"
                            :reduce="(countries) => countries.slug"
                            class="select-size-sm"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                      <b-form-group
                        label="Département"
                        label-for="departement_depart"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="département"
                          rules="required"
                        >
                          <v-select
                            id="departement_depart"
                            v-model="form.departement_depart"
                            label="name"
                            :state="errors.length > 0 ? false : null"
                            :options="currentDepartements"
                            placeholder="Choisir un département"
                            :reduce="(currentDepartements) => currentDepartements.id"
                            class="select-size-sm"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <b-form-group
                        label="Ville"
                        label-for="ville_depart"
                      >

                        <validation-provider
                          #default="{ errors }"
                          name="ville"
                          rules="required"
                        >
                          <v-select
                            id="ville_depart"
                            v-model="form.ville_depart_id"
                            label="name"
                            :state="errors.length > 0 ? false : null"
                            :options="currentVilles"
                            placeholder="Choisir une ville"
                            :reduce="(currentVilles) => currentVilles.id"
                            class="select-size-sm"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label="Information sur l'arrivée de la marchandise"
                        class="text-primary"
                      />
                      <b-form-group
                        label="Pays"
                        label-for="country_arrive"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="pays"
                          rules="required"
                        >
                          <v-select
                            id="country_arrive"
                            v-model="form.country_arrive"
                            label="country_name"
                            :state="errors.length > 0 ? false : null"
                            :options="countries"
                            placeholder="Choisir un pays"
                            :reduce="(countries) => countries.slug"
                            class="select-size-sm"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <b-form-group
                        label="Département"
                        label-for="departement_arrive"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="département"
                          rules="required"
                        >
                          <v-select
                            id="departement_arrive"
                            v-model="form.departement_arrive"
                            label="name"
                            :state="errors.length > 0 ? false : null"
                            :options="currentDepartements"
                            placeholder="Choisir un département"
                            :reduce="(currentDepartements) => currentDepartements.slug"
                            class="select-size-sm"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <b-form-group
                        label="Ville"
                        label-for="ville_arrive"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="ville d'arrivée"
                          rules="required"
                        >
                          <v-select
                            id="ville_arrive"
                            v-model="form.ville_destination_id"
                            label="name"
                            :state="errors.length > 0 ? false : null"
                            :options="currentVillesArrive"
                            placeholder="Choisir une ville"
                            :reduce="(currentVilles) => currentVilles.id"
                            class="select-size-sm"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                    <b-col md="12">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="danger"
                        class="float-right"
                        onclick="history.back()"
                      >
                        <feather-icon icon="ArrowLeftCircleIcon" />
                        Annuler
                      </b-button>
                    </b-col>

                  </b-row>
                </validation-observer>
              </tab-content>

              <!-- profil detail tab -->
              <tab-content :before-change="validationMarchandise">
                <validation-observer
                  ref="marchandiseRules"
                  tag="form"
                >
                  <b-row>
                    <b-col
                      md="12"
                      class="mb-2"
                    >
                      <h5 class="mb-0">
                        Informations sur l'Objet / Marchandises
                      </h5>
                      <small class="text-muted" />
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label="Catégorie"
                        label-for="categorie_marchandise_slug"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="catégorie"
                          rules="required"
                        >
                          <v-select
                            id="categorie_marchandise_slug"
                            v-model="form.categorie_marchandise_slug"
                            label="libelle"
                            :state="errors.length > 0 ? false : null"
                            :options="categoryMarchandises"
                            placeholder="Choisir une catégorie de marchandise"
                            :reduce="(categoryMarchandises) => categoryMarchandises.slug"
                            class="select-size-sm"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <b-form-group
                        label="Longueur"
                        label-for="longueur"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="longueur"
                          rules="required"
                        >
                          <b-input-group append="mètre">
                            <b-form-input
                              v-model="form.longueur"
                              type="number"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Longueur"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col md="6">

                      <b-form-group
                        label="Type de marchandise"
                        label-for="marchandise_slug"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="type marchandise"
                          rules="required"
                        >
                          <v-select
                            id="marchandise_slug"
                            v-model="form.marchandise_slug"
                            label="name"
                            :state="errors.length > 0 ? false : null"
                            :options="currentType"
                            placeholder="Choisir un type de marchandise"
                            :reduce="(currentType) => currentType.slug"
                            class="select-size-sm"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <b-form-group
                        label="Volume"
                        label-for="volume"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="volume"
                          rules="required"
                        >
                          <b-input-group append="m³">
                            <b-form-input
                              v-model="form.volume"
                              type="number"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Volume"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                    <b-col md="6">
                      <b-row>
                        <b-col md="8">
                          <b-form-group
                            label="Poids"
                            label-for="poids_marchandise"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="poids_marchandise"
                              rules="required"
                            >
                              <b-form-input
                                v-model="form.poids_marchandise"
                                size="sm"
                                type="number"
                                :state="errors.length > 0 ? false : null"
                                placeholder="Poids"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="4">
                          <b-form-group
                            label="Unité de mesure"
                            label-for="unite_mesure_id"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="unité de mesure"
                              rules="required"
                            >
                              <v-select
                                id="unite_mesure_id"
                                v-model="form.unite_mesure_id"
                                label="libelle"
                                :state="errors.length > 0 ? false : null"
                                :options="mesures"
                                placeholder="Mesure"
                                :reduce="(mesures) => mesures.id"
                                class="select-size-sm"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label-for="fichier"
                        label="Pièce jointe"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="pièce jointe"
                        >
                          <b-form-file
                            id="fichier"
                            accept="image/*"
                            size="sm"
                            placeholder="Choisir un fichier"
                            @change="handleImageSelected"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="danger"
                        class="float-right"
                        onclick="history.back()"
                      >
                        <feather-icon icon="ArrowLeftCircleIcon" />
                        Annuler
                      </b-button>
                    </b-col>
                  </b-row>
                </validation-observer>
              </tab-content>

              <!-- account detail tab -->
              <tab-content :before-change="validationConditionForm">
                <validation-observer
                  ref="conditionRules"
                  tag="form"
                >
                  <b-row>
                    <b-col
                      md="12"
                      class="mb-2"
                    >
                      <h5 class="mb-0">
                        Informations sur les conditions
                      </h5>
                      <small class="text-muted" />
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label-for="date_prev_depart"
                        label-size="lg"
                      >
                        <span>Date et heure de chargement</span>
                        <validation-provider
                          #default="{ errors }"
                          name="date"
                          rules="required"
                        >
                          <flat-pickr
                            v-model="form.date_prev_depart"
                            class="form-control"
                            size="sm"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Date départ"
                            :config="config"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label-for="date_prev_livraison"
                        label-size="lg"
                      >
                        <span>Date et heure de livraison</span>
                        <validation-provider
                          #default="{ errors }"
                          name="date livraison"
                          rules="required"
                        >
                          <flat-pickr
                            v-model="form.date_prev_livraison"
                            class="form-control"
                            size="sm"
                            placeholder="Date livraison"
                            :state="errors.length > 0 ? false : null"
                            :config="config"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                  </b-row>
                  <b-row>
                    <b-col sm="12">
                      <label
                        for="textarea-small"
                        class="text-nowrap"
                      >Commentaire:</label>
                    </b-col>
                    <b-col
                      sm="12"
                      class="mb-1"
                    >
                      <b-form-textarea
                        id="textarea-small"
                        v-model="form.commentaire"
                        size="sm"
                        placeholder="Commentaire"
                      />
                    </b-col>
                    <b-col md="12">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="danger"
                        class="float-right"
                        onclick="history.back()"
                      >
                        <feather-icon icon="ArrowLeftCircleIcon" />
                        Annuler
                      </b-button>
                    </b-col>

                  </b-row>
                </validation-observer>
              </tab-content>

              <!-- account detail tab -->
              <tab-content :before-change="validationContactForm">
                <validation-observer
                  ref="contactRules"
                  tag="form"
                >
                  <b-row class="justify-content-md-center">
                    <b-col
                      md="12"
                      class="mb-2"
                    >
                      <h5 class="mb-0">
                        Informations du contact immédiat
                      </h5>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label="Nom"
                        label-for="nom_contact"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="nom"
                          rules="required"
                        >
                          <b-form-input
                            v-model="form.nom_contact"
                            size="sm"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Nom"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label="Email"
                        label-for="email_contact"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="email"
                          rules="required|email"
                        >
                          <b-form-input
                            v-model="form.email_contact"
                            size="sm"
                            type="email"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Email"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="6">
                      <b-form-group
                        label="Téléphone"
                        label-for="telephone_contact"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="téléphone"
                          rules="required"
                        >
                          <VuePhoneNumberInput
                            v-model="form.telephonefull"
                            default-country-code="BJ"
                            show-code-on-list
                            size="sm"
                            :translations="configPhoneNumber"

                            @update="telephonefull = $event"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="6">
                      <b-form-group
                        label="Partagez avec votre réseau de transporteurs"
                        label-for="diffusion"
                      >
                        <validation-provider
                          name="diffusion"
                          rules="required"
                        >
                          <b-form-checkbox
                            v-model="form.diffusion"
                          >
                            {{ form.diffusion? 'Oui':'Non' }}
                          </b-form-checkbox>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="danger"
                        class="float-right"
                        onclick="history.back()"
                      >
                        <feather-icon icon="ArrowLeftCircleIcon" />
                        Annuler
                      </b-button>
                    </b-col>
                  </b-row>
                </validation-observer>
              </tab-content>
            </form-wizard>
          </b-card>
        </b-overlay>

      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash'

import {
  ref, reactive, onMounted, watch,
} from '@vue/composition-api'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, confirmed, password,
} from '@validations'
import VuePhoneNumberInput from 'vue-phone-number-input'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies, import/extensions
import { French } from 'flatpickr/dist/l10n/fr.js'

import {
  BRow,
  BCol,
  BFormTextarea,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BContainer,
  BCard,
  BButton,
  BFormCheckbox,
  BFormFile,
  BOverlay,

} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

// eslint-disable-next-line import/no-cycle
import useImageUpload from '@/services/upload/useImageUpload'
// eslint-disable-next-line import/no-cycle
import useCountries from '@/services/admin/countryService'
// eslint-disable-next-line import/no-cycle
import useDepartements from '@/services/admin/departementService'
// eslint-disable-next-line import/no-cycle
import useCategoryMarchandises from '@/services/admin/categoryMarchandiseService'
// eslint-disable-next-line import/no-cycle
import useMesures from '@/services/admin/uniteDeMesureService'
// eslint-disable-next-line import/no-cycle
import useOffres from '@/services/affreteur/offresService'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
// eslint-disable-next-line import/no-cycle
import useVilles from '@/services/admin/villeService'

export default {
  components: {
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BContainer,
    BFormCheckbox,
    BFormFile,
    flatPickr,
    BOverlay,
    vSelect,
    VuePhoneNumberInput,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      confirmed,
      password,
    }
  },
  setup() {
    const {
      getCountries, countries, getCountry, country,
    } = useCountries()
    const { getDepartement, departement, getDepartementById } = useDepartements()
    const {
      getCategoryMarchandises, categoryMarchandises, getCategoryMarchandise, categoryMarchandise,
    } = useCategoryMarchandises()
    const {
      getVilleById, ville,
    } = useVilles()
    const { getMesures, mesures } = useMesures()

    const {
      updateOffre, getOffre, offre, offreProcess,
    } = useOffres()

    const offreCode = ref('')

    const {
      imageUrl,
      imageFile,
      handleImageSelected,
      uploadFile,
      fileName,
      deleteFile,
    } = useImageUpload()

    const fields = reactive([
      { key: 'type_document', label: 'Type de document' },
      { key: 'numero_de_la_piece', label: 'Numéro de la pièce' },
      { key: 'date_expiration', label: "Date d'expiration" },
      { key: 'actions' },
    ])
    const items = reactive([])

    const form = reactive({
      ville_depart_id: '',
      country_depart: '',
      departement_depart: '',

      country_arrive: '',
      ville_destination_id: '',
      departement_arrive: '',

      categorie_marchandise_slug: '',
      marchandise_slug: '',
      unite_mesure_id: '',

      poids_marchandise: '',
      volume: '',
      image: '',
      longueur: '',

      date_prev_depart: '',
      date_prev_livraison: '',

      nom_contact: '',
      email_contact: '',
      telephone_contact: '',
      telephonefull: '',
      diffusion: '',
      commentaire: '',

    })

    const editOffre = async code => {
      await getOffre(code)
      form.image = offre.value.image
      form.ville_depart_name = offre.value.ville_depart.name
      form.code = offre.value.code
      form.ville_depart_id = offre.value.ville_depart.id
      form.ville_destination_id = offre.value.ville_destination.id
      form.ville_destination_name = offre.value.ville_destination.name
      await getDepartementById(+offre.value.ville_destination.departement_id)
      form.country_arrive = departement.value.country.country_name
      await getDepartementById(+offre.value.ville_depart.departement_id)
      form.country_depart = departement.value.country.country_name
      await getVilleById(offre.value.ville_depart.id)
      form.departement_depart = ville.value.departement.name
      await getVilleById(offre.value.ville_destination.id)
      form.departement_arrive = ville.value.departement.name
      form.categorie_marchandise_slug = offre.value.categorie_marchandise.slug
      form.unite_mesure_id = offre.value.unite_mesure.id
      form.marchandise_slug = offre.value.marchandise.slug
      form.poids_marchandise = offre.value.poids_marchandise
      form.volume = offre.value.volume
      form.commentaire = offre.value.commentaire
      form.date_prev_depart = offre.value.date_prev_depart
      form.date_prev_livraison = offre.value.date_prev_livraison
      form.email_contact = offre.value.email_contact
      form.nom_contact = offre.value.nom_contact
      form.telephone_contact = offre.value.telephone_contact
      form.telephonefull = offre.value.telephone_contact
      form.longueur = offre.value.longueur
      form.diffusion = offre.value.diffusion !== 'PUBLIC'
    }
    onMounted(async () => {
      await getCountries()
      await getCategoryMarchandises()
      await getMesures()
      if (router.currentRoute.params.code) {
        offreCode.value = router.currentRoute.params.code
        await editOffre(offreCode.value)
      }
      // Obtenir une devise par son slug
    })
    const telephonefull = ref('')
    // eslint-disable-next-line no-unused-vars
    watch(() => (form.telephonefull), value => {
      form.telephone_contact = ''
      form.telephone_contact = telephonefull.value.formattedNumber
      console.clear()
    })

    // S'Enregistrer
    const storeOffre = async () => {
      const result = imageFile.value instanceof File
      if (result) {
        const data = new FormData()
        data.append('fichier', imageFile.value)
        await deleteFile(form.image)
        await uploadFile(data)
        form.image = fileName.value
      }
      await updateOffre(form)
      console.clear()
    }
    const currentDepartements = ref([])
    const currentCountry = async data => {
      console.clear()
      country.value = ''
      await getCountry(data)
      currentDepartements.value = country.value.departements
    }

    const currentVilles = ref([])

    const currentDepartement = async data => {
      console.clear()
      departement.value = ''
      await getDepartement(data)
      currentVilles.value = departement.value.villes
    }

    const currentType = ref([])

    const currentMarchandise = async data => {
      console.clear()
      await getCategoryMarchandise(data)
      currentType.value = categoryMarchandise.value.marchandises
    }

    const currentVillesArrive = ref([])

    const currentDepartementArrive = async data => {
      console.clear()
      departement.value = ''

      await getDepartement(data)
      currentVillesArrive.value = departement.value.villes
    }

    watch(() => _.cloneDeep(form.country_depart), value => {
      currentCountry(value)
    })
    watch(() => _.cloneDeep(form.departement_depart), value => {
      currentDepartement(value)
    })
    watch(() => _.cloneDeep(form.country_arrive), value => {
      currentCountry(value)
    })
    watch(() => _.cloneDeep(form.departement_arrive), value => {
      currentDepartementArrive(value)
    })
    watch(() => _.cloneDeep(form.categorie_marchandise_slug), value => {
      currentMarchandise(value)
    })

    const config = {
      wrap: true, // set wrap to true only when using 'input-group'
      altFormat: 'j M Y H i',
      altInput: true,
      enableTime: true,
      dateFormat: 'Y-m-d H:i',
      locale: French, // locale for this instance only
    }

    return {
      config,
      countries,
      currentDepartements,
      currentVilles,
      currentVillesArrive,
      categoryMarchandises,
      mesures,
      currentType,
      form,
      items,
      fields,
      storeOffre,
      imageUrl,
      handleImageSelected,
      telephonefull,
      offreProcess,
      offre,
      offreCode,
    }
  },

  methods: {
    formatter(value) {
      return value.toLowerCase()
    },

    validationMarchandise() {
      return new Promise((resolve, reject) => {
        this.$refs.marchandiseRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },

    validationIsConditionForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountConditionRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationConditionForm() {
      return new Promise((resolve, reject) => {
        this.$refs.conditionRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },

    ValidationTrajetForm() {
      return new Promise((resolve, reject) => {
        this.$refs.trajetRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationContactForm() {
      return new Promise((resolve, reject) => {
        this.$refs.contactRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },

  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
